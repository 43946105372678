import React, { useEffect, useState } from "react";
import { Button, Table, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { NavLink, Redirect } from "react-router-dom";

const ProductsList = () => {
  const [products, setProducts] = useState([]);
  const [viewProductId, setViewProductId] = useState(false);

  const getProducts = async () => {
    try {
      const response = await axios.get("/product/admin-list");
      setProducts(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getProducts();
    })();
  }, []);

  const onClickDelete = async (event, productId) => {
    event.stopPropagation();

    try {
      const response = await axios.delete(`/product/${productId}`);

      if (response) {
        const filteredProducts = products.filter((product) => product.id !== productId);
        setProducts(filteredProducts);
        message.success("Successfully deleted product");
      }
    } catch (e) {
      message.error("Something went wrong");
    }
  };

  if (viewProductId) {
    return <Redirect push to={`/products/${viewProductId}/update`} />;
  }

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => text.name,
    },
    {
      title: "RRP",
      dataIndex: "rrp",
      key: "rrp",
    },
    {
      title: "Start price",
      dataIndex: "priceStart",
      key: "priceStart",
    },
    {
      title: "End price",
      dataIndex: "priceEnd",
      key: "priceEnd",
    },
    {
      title: "",
      key: "action_delete",
      render: (text, record) => <DeleteOutlined onClick={(event) => onClickDelete(event, record.id)} />,
    },
  ];

  return (
    <>
      <section>
        <h1>Products</h1>
      </section>

      <section>
        <Button>
          <NavLink to={`/products/add`}>Add new product</NavLink>
        </Button>
      </section>

      <section>
        <Table
          dataSource={products}
          columns={columns}
          rowKey="id"
          pagination={{
            pageSize: 50,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "50", "100"],
            hideOnSinglePage: true,
          }}
          onRow={(record) => ({
            onClick: () => {
              setViewProductId(record.id);
            },
          })}
        />
      </section>
    </>
  );
};

export default ProductsList;
